import { localStorageKeys } from '../../utils/constants';
import { getLocalStorageData } from '../localStorageData';

function SetThemeConfig() {
  const localData = getLocalStorageData(localStorageKeys.currentEvent);
  const body = document.body;
  body.style.setProperty('--primary', localData?.themes[0]?.primaryColor);
  body.style.setProperty('--secondary', localData?.themes[0]?.secondaryColor);
  body.style.setProperty(
    '--backgroundColor',
    localData?.themes[0]?.backgroundColor,
  );
  body.style.setProperty('--fontPrimary', localData?.themes[0]?.fontFamily);
  body.style.setProperty(
    '--bgPrimary',
    `url(${localData?.themes[0]?.backgroundImage})`,
  );
  body.className = 'scroll-y-auto';
}

export default SetThemeConfig;
