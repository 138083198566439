import * as React from 'react';
import AppProviders from './Providers';
import ProtectedApp from './ProtectedApp';
import SetThemeConfig from '../core/setThemeConfig';
import LoadingPage from '../components/LoadingPage';
import './App.scss';

export default function App() {
  SetThemeConfig();
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <AppProviders>
        <ProtectedApp />
      </AppProviders>
    </React.Suspense>
  );
}
