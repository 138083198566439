export default {
  app: Object.freeze({
    path: '/',
  }),
  NotFound: Object.freeze({
    path: '/*',
  }),
  Setup: Object.freeze({
    path: '/setup/:id',
  }),
  level: Object.freeze({
    path: '/level',
  }),
  cultureQuizPage: Object.freeze({
    path: '/culture-quiz',
  }),
  Themes: Object.freeze({
    path: '/themes',
  }),
  subTheme: Object.freeze({
    path: '/subTheme/:id',
  }),
  questionPage: Object.freeze({
    path: '/questions',
  }),
  scorePage: Object.freeze({
    path: '/score',
  }),
  rate: Object.freeze({
    path: '/rate',
  }),
  quizInstructions: Object.freeze({
    path: '/quiz-instructions',
  }),
  timeOutPage: Object.freeze({
    path: '/time-out',
  }),
  Images: Object.freeze({
    path: '/api/v1/file-store/',
  }),
};
