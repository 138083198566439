import { Spin } from 'antd';
import { t } from 'i18next';
import React from 'react';
import {
  getCurrentEvent,
  getSelectedEventBg,
} from '../../core/localStorageData';
import { formattedMessages } from '../../utils/formattedMessages';
import './styles.scss';

function LoadingPage() {
  const backgroundImg = getSelectedEventBg();
  const ownedByXPortfolio = getCurrentEvent()?.ownedByXPortfolio;
  const loadingBg = ownedByXPortfolio
    ? { backgroundImage: 'none' }
    : { backgroundImage: `url('${backgroundImg}')` };

  return (
    <div
      style={loadingBg}
      className="flex flex-col justify-center items-center max-w-screen w-screen max-h-screen h-screen bg-cover bg-center bg-no-repeat"
    >
      <Spin tip={t(formattedMessages.loading)} />
    </div>
  );
}

export default LoadingPage;
