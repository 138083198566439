import ls from 'localstorage-slim';
import {
  getLocalStorageTestData,
  getTestBg,
  getTestSeniortyLevelId,
  getTestUserData,
} from '../../tests/functions';
import { XEventDetails } from '../../types';
import { __TEST__, localStorageKeys } from '../../utils/constants';

// store to localStorage

export function storeCurrentEvent(data: XEventDetails) {
  ls.set(localStorageKeys.currentEvent, JSON.stringify(data), {
    encrypt: true,
  });
}

export function storeNumberOfQuestions(numberOfQuestions: number) {
  ls.set(
    localStorageKeys.numberOfQuestions,
    JSON.stringify(numberOfQuestions),
    {
      encrypt: true,
    },
  );
}

export function storeFirstQuestion(question: string) {
  ls.set(localStorageKeys.currentQuestion, JSON.stringify(question), {
    encrypt: true,
  });
}

export function storeUserData(id: string) {
  ls.set(localStorageKeys.sessionId, JSON.stringify({ sessionId: id }), {
    encrypt: true,
  });
}

export function storeIsAbleToRate() {
  ls.set(localStorageKeys.isAbleToRate, JSON.stringify(true), {
    encrypt: true,
  });
}

export function storeScore(score: any) {
  ls.set(localStorageKeys.score, JSON.stringify(score), {
    encrypt: true,
  });
}

export function setSeniortyLevelId(levelId: string) {
  ls.set(localStorageKeys.seniorityLevelId, JSON.stringify(levelId), {
    encrypt: true,
  });
}

// get from localStorage

export function getLocalStorageData(itemToGet: string) {
  if (__TEST__) return getLocalStorageTestData(itemToGet);
  return JSON.parse(ls.get(itemToGet, { decrypt: true }));
}

export function getUserData() {
  if (__TEST__) return getTestUserData();
  return JSON.parse(ls.get(localStorageKeys.sessionId, { decrypt: true }));
}

export function getSelectedEventBg() {
  if (__TEST__) return getTestBg();
  const selectedTheme = getLocalStorageData(localStorageKeys.currentEvent);

  return selectedTheme?.setup?.length === 1
    ? selectedTheme?.setup[0]?.formSetup?.theme?.backgroundImage
    : selectedTheme?.setup[1]?.formSetup?.theme?.backgroundImage;
}

export function getSelectedEventSetupBackground(currentEvent) {
  return currentEvent?.setup?.length === 1
    ? currentEvent?.setup[0]?.formSetup?.theme?.backgroundImage
    : currentEvent?.setup[1]?.formSetup?.theme?.backgroundImage;
}

export function getSeniortyLevelId() {
  if (__TEST__) return getTestSeniortyLevelId();
  return JSON.parse(
    ls.get(localStorageKeys.seniorityLevelId, { decrypt: true }),
  );
}

export function getCurrentEvent() {
  if (__TEST__) return getCurrentEvent();
  return JSON.parse(ls.get(localStorageKeys.currentEvent, { decrypt: true }));
}

// remove from localStorage

export function clearQuestionData() {
  window.localStorage.removeItem(localStorageKeys.currentQuestion);
}

export function clearNumberOfQuestions() {
  window.localStorage.removeItem(localStorageKeys.numberOfQuestions);
}

export function clearIsAbleToRate() {
  window.localStorage.removeItem(localStorageKeys.isAbleToRate);
}

export function clearCurrentQuestion() {
  window.localStorage.removeItem(localStorageKeys.currentQuestion);
}

export function clearSeniorityLevelId() {
  window.localStorage.removeItem(localStorageKeys.seniorityLevelId);
}

export function clearUserData() {
  return localStorage.removeItem(localStorageKeys.sessionId);
}

export function clearCurrentEvent() {
  return localStorage.removeItem(localStorageKeys.currentEvent);
}

export function clearSessionData() {
  clearSeniorityLevelId();
  clearCurrentQuestion();
  clearUserData();
  clearNumberOfQuestions();
  clearIsAbleToRate();
}
