import { useTranslation } from 'react-i18next';

const scopes = Object.freeze([
  'xQuiz',
  'home',
  'user',
  'formRules',
  'LogoutConfirmationModalMsg',
  'Instructions',
  'Welcome',
  'Quiz',
  'register',
  'rate',
  'errorMsg',
  'theme',
  'subTheme',
  'scoring',
  'level',
  'cultureQuiz',
  'notFound',
  'TechnicalQuizToolTip',
  'timeOut',
  'CulturalQuizToolTip',
]);

export const formattedMessages = Object.freeze({
  // company
  xQuiz: `${scopes[0]}.title`,

  // home
  commencer: `${scopes[1]}.commencer`,
  noEvent: `${scopes[1]}.noEvent`,
  homeTitle: `${scopes[1]}.title`,
  unvalidSelect: `${scopes[1]}.unvalidSelect`,
  selectEventPlaceholder: `${scopes[1]}.selectEventPlaceholder`,

  // user
  userFirstName: `${scopes[2]}.firstName`,
  userLastName: `${scopes[2]}.lastName`,
  userEmail: `${scopes[2]}.email`,
  userPhone: `${scopes[2]}.phoneNumber`,
  userRegisterButton: `${scopes[2]}.registerNow`,

  // Form rules
  required: `${scopes[3]}.required`,
  alphabetic: `${scopes[3]}.alphabetic`,
  name: `${scopes[3]}.name`,
  email: `${scopes[3]}.email`,
  phone: `${scopes[3]}.phone`,
  alphanumeric: `${scopes[3]}.alphanumeric`,
  min: `${scopes[3]}.min`,

  // LogoutConfirmationModalMsg
  LogoutConfirmationModalMsg: `${scopes[4]}.confirmationModalMsg`,
  LogoutConfirmationModalTitle: `${scopes[4]}.confirmationModalTitle`,

  // Instructions
  Instructions: `${scopes[5]}.title`,
  whitespace: `${scopes[5]}.whitespace`,
  timeLimit: `${scopes[5]}.timeLimit`,
  levelNotFound: `${scopes[5]}.levelNotFound`,

  // Welcoming
  Welcome: `${scopes[6]}.Welcome`,
  welcomeToEventName: `${scopes[6]}.welcomeToEventName`,
  welcomeMessage: `${scopes[6]}.message`,
  eventDate: `${scopes[6]}.eventDate`,
  eventNotFound: `${scopes[6]}.eventNotFound`,

  // Quiz
  startQuiz: `${scopes[7]}.StartQuiz`,
  backToQuiz: `${scopes[7]}.backToQuiz`,
  timeLeft: `${scopes[7]}.timeLeft`,
  chooseMultipleAnswers: `${scopes[7]}.chooseMultipleAnswers`,
  NextQuestion: `${scopes[7]}.NextQuestion`,
  reminder: `${scopes[7]}.reminder`,
  reminderTitle: `${scopes[7]}.reminderTitle`,
  quizAlreadyDone: `${scopes[7]}.quizAlreadyDone`,
  seeResults: `${scopes[7]}.seeResults`,
  FinishQuiz: `${scopes[7]}.FinishQuiz`,
  quizTitle: `${scopes[7]}.title`,

  // register
  registerfirstChoice: `${scopes[8]}.firstChoice`,
  registersecondChoice: `${scopes[8]}.secondChoice`,
  sessionNotFound: `${scopes[8]}.sessionNotFound`,

  // rate
  rateFirstMsg: `${scopes[9]}.firstMsg`,
  rateSecondMsg: `${scopes[9]}.secondMsg`,
  ratePlaceholder: `${scopes[9]}.placeholder`,
  rateSendButton: `${scopes[9]}.SendButton`,
  rateModalFirstMsg: `${scopes[9]}.ConfirmationModalFirstMsg`,
  rateModalSecondMsg: `${scopes[9]}.ConfirmationModalSecondMsg`,
  rateModalTitle: `${scopes[9]}.ConfirmationModalTitle`,
  invalidLink: `${scopes[9]}.invalidLink`,
  rateTitle: `${scopes[9]}.title`,
  ratingError: `${scopes[9]}.ratingError`,

  // theme
  themeTitle: `${scopes[11]}.title`,
  noTheme: `${scopes[11]}.noTheme`,

  // subTheme
  subThemeTitle: `${scopes[12]}.title`,
  emptyTheme: `${scopes[12]}.emptyTheme`,
  returnHome: `${scopes[12]}.returnHome`,

  // errors
  invalidError: `${scopes[10]}.invalidError`,
  rateError: `${scopes[10]}.rateError`,
  startQuizError: `${scopes[10]}.startQuizError`,
  xPortfolioCondidate: `${scopes[10]}.xPortfolioCondidate`,

  // scoring
  scoring: `${scopes[13]}.scoring`,
  singleAnswer: `${scopes[13]}.singleAnswer`,
  multipleAnswer: `${scopes[13]}.multipleAnswer`,
  singleAnswerWrongScore: `${scopes[13]}.singleAnswerWrongScore`,
  singleAnswerRightScore: `${scopes[13]}.singleAnswerRightScore`,
  multipleAnswerWrongScore: `${scopes[13]}.multipleAnswerWrongScore`,
  multipleAnswerRightScore: `${scopes[13]}.multipleAnswerRightScore`,
  failedQuizTitle: `${scopes[13]}.failedQuizTitle`,
  passedQuizTitle: `${scopes[13]}.passedQuizTitle`,
  failedQuizDescription: `${scopes[13]}.failedQuizDescription`,
  passedQuizDescription: `${scopes[13]}.passedQuizDescription`,
  getGoodyMsg: `${scopes[13]}.getGoodyMsg`,
  noScoreFound: `${scopes[13]}.noScoreFound`,
  thanksMessage: `${scopes[13]}.thanksMessage`,
  xportfolioThanks: `${scopes[13]}.xportfolioThanks`,
  goodLuck: `${scopes[13]}.goodLuck`,

  // level
  noLevel: `${scopes[14]}.noLevel`,

  // cultureQuiz
  cultureQuiz: `${scopes[15]}.title`,
  cultureQuizButton: `${scopes[15]}.finishQuiz`,
  cultureQuizModal: `${scopes[15]}.confirmationMsg`,

  // error page
  backToSetup: 'backToSetup',
  backToHome: 'backToHome',
  backToTheme: 'backToTheme',
  backToLevel: 'backTolevelpage',
  rateExperiance: 'rateExperiance',
  backTScore: 'backTScore',
  eventNotActive: `${scopes[10]}.eventNotActive`,

  // not found page
  goBack: `${scopes[16]}.goBack`,
  notFoundMsg: `${scopes[16]}.notFoundMsg`,
  error404: '404',

  // time out page
  seeScore: `${scopes[18]}.seeScore`,
  timeOutMsg: `${scopes[18]}.timeOutMsg`,

  // technical Quiz toolTip
  toolTipTitle: `${scopes[17]}.toolTipTitle`,
  technicalQuizSingleAnswer: `${scopes[17]}.singleAnswer`,
  wrongSingleAnswerScore: `${scopes[17]}.wrongSingleAnswerScore`,
  rightSingleAnswerScore: `${scopes[17]}.rightSingleAnswerScore`,
  technicalQuizMultipleAnswer: `${scopes[17]}.multipleAnswer`,
  wrongMultipleAnswerScore: `${scopes[17]}.wrongMultipleAnswerScore`,
  rightMultipleAnswerScore: `${scopes[17]}.rightMultipleAnswerScore`,

  // Cultural Quiz toolTip
  toolTipDescriptionOne: `${scopes[19]}.toolTipDescriptionOne`,
  toolTipDescriptionTwo: `${scopes[19]}.toolTipDescriptionTwo`,

  // non scoped messages
  EventIsNotActive: ' Sorry... This event does not seem to be active!',
  requiredScanCode: `requiredScanCode`,
  footer: 'footer',
  error: 'error',
  warning: 'warning',
  success: 'success',
  continue: 'continue',
  yes: 'yes',
  no: 'no',
  updating: 'updating',
  sending: 'sending',
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  deleting: 'deleting',
  retry: 'retry',
  save: 'save',
  cancel: 'cancel',
  search: 'search',
  title: 'title',
  loading: 'loading...',
  thankYou: 'thankYou',
  ok: 'ok',
  mad: 'MAD',
  skip: 'Skip',
});

export function getFormattedMessage(name: string) {
  const { t } = useTranslation();
  return t(formattedMessages[name]);
}
