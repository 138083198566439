import * as React from 'react';

import { globalRoutes } from './constants';
import AppRouting from '../Routing';
import { getCurrentEvent } from '../../core/localStorageData';

function ProtectedApp() {
  const quizCreatedByXPortfolio = getCurrentEvent()?.ownedByXPortfolio;

  const routes =
    globalRoutes[
      quizCreatedByXPortfolio
        ? 'APP_ROUTES_FOR_QUIZES_CREATED_BY_XPORTFOLIO'
        : 'APP_ROUTES_CONFIG'
    ];

  return (
    <div>
      <AppRouting routes={routes} />
    </div>
  );
}
export default ProtectedApp;
