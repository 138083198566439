import { QuestionStateType } from '../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __DEV__ = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const __TEST__ = process.env.NODE_ENV === 'test';

export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_STRING = Object.freeze('');

export const fetchStatusTypes = Object.freeze({
  INITIAL: 'initial',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  ABORTED: 'aborted',
});

export const actions = {
  run: 'run',
  selectTheme: 'select-theme',
};

export const timing = {
  twoMin: '02 : 00',
  timeEnd: '00 : 00',
};

export const localStorageKeys = Object.freeze({
  numberOfQuestions: 'numberOfQuestions',
  seniorityLevelId: 'seniority-levelId',
  currentQuestion: 'current-question',
  isAbleToRate: 'isAbleToRate',
  currentEvent: 'currentEvent',
  sessionId: 'sessionId',
  score: 'score',
});

export const initialQuestionState: QuestionStateType = {
  isButtonDisabled: true,
  currentQuestion: {
    question: {
      id: '',
      index: 0,
      answers: [{ id: '', content: '' }],
      content: '',
      totalCorrectAnswers: 1,
      isMultipleChoice: false,
    },
  },
  pagination: 1,
  chosenAnswers: [],
};

export const colors = {
  footerDefaultColor: '#000',
  footerBlackColor: '#3B3A3B',
};
