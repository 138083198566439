import { localStorageKeys } from '../utils/constants';
import {
  GET_LOCALSTORAGE_CURRENT_EVENT,
  GET_LOCALSTORAGE_CURRENT_QUESTION,
  GET_LOCALSTORAGE_ISABLETORATE,
  GET_LOCALSTORAGE_NUMBEROFQUESTIONS,
  GET_LOCALSTORAGE_SESSION_ID,
} from './localStorageTestData';

export function getLocalStorageTestData(itemToGet: string) {
  switch (itemToGet) {
    case localStorageKeys.currentQuestion:
      return GET_LOCALSTORAGE_CURRENT_QUESTION;

    case localStorageKeys.currentEvent:
      return GET_LOCALSTORAGE_CURRENT_EVENT;

    case localStorageKeys.isAbleToRate:
      return GET_LOCALSTORAGE_ISABLETORATE;

    case localStorageKeys.numberOfQuestions:
      return GET_LOCALSTORAGE_NUMBEROFQUESTIONS;

    case localStorageKeys.sessionId:
      return GET_LOCALSTORAGE_SESSION_ID;

    default:
      return '';
  }
}

export function getTestUserData() {
  return '1';
}

export function getTestBg() {
  return 'background.img';
}

export function getTestSeniortyLevelId() {
  return '1';
}
