export const GET_LOCALSTORAGE_CURRENT_EVENT = {
  id: '1',
  name: 'DEVOXX MOROCCO',
  logo: 'https://res.cloudinary.com/dizlyig0d/image/upload/v1663631062/Group_1_jry255.png',
  location: 'AGADIR',
  eventDates: [
    '2022-10-26',
    '2022-10-27',
    '2022-10-28',
    '2022-10-29',
    '2022-10-30',
    '2022-10-31',
    '2022-11-01',
    '2022-11-02',
    '2022-11-03',
    '2022-11-04',
    '2022-11-05',
    '2022-11-06',
    '2022-11-07',
    '2022-11-08',
    '2022-11-09',
    '2022-11-10',
    '2022-11-11',
    '2022-11-12',
    '2022-11-13',
    '2022-11-14',
    '2022-11-15',
    '2022-11-16',
    '2022-11-17',
    '2022-11-18',
    '2022-11-19',
    '2022-11-20',
    '2022-11-21',
    '2022-11-22',
    '2022-11-23',
    '2022-11-24',
    '2022-11-25',
    '2022-11-26',
    '2022-11-27',
    '2022-11-28',
    '2022-11-29',
    '2022-11-30',
    '2022-12-01',
    '2022-12-02',
    '2022-12-03',
    '2022-12-04',
    '2022-12-05',
    '2022-12-06',
    '2022-12-07',
    '2022-12-08',
    '2022-12-09',
    '2022-12-10',
    '2022-12-11',
    '2022-12-12',
    '2022-12-13',
    '2022-12-14',
    '2022-12-15',
    '2022-12-16',
    '2022-12-17',
    '2022-12-18',
    '2022-12-19',
    '2022-12-20',
    '2022-12-21',
    '2022-12-22',
    '2022-12-23',
    '2022-12-24',
    '2022-12-25',
    '2022-12-26',
    '2022-12-27',
    '2022-12-28',
    '2022-12-29',
    '2022-12-30',
  ],
  setup: [
    {
      id: '1',
      title: 'Welcome to Devoxx Quiz',
      description:
        'This quiz is made for you in order to book your interview slot and possibily join our xFamily.',
      kind: 'SCAN',
      initializeButtonText: 'Register now',
      formSetup: {
        formName: 'qr-code',
        formTitle: 'registration form',
        submitUrl: '/api/v1/quiz-instance',
        submitMethod: 'POST',
        elements: [
          {
            id: '6',
            type: 'input',
            name: 'registrationCode',
            placeholder: 'Your registration code',
            required: true,
            htmlType: 'text',
          },
          {
            id: '7',
            type: 'button',
            name: 'GO',
            innerText: 'GO',
            htmlType: 'submit',
          },
        ],
        theme: {
          id: '3',
          active: true,
          backgroundImage: 'https://svgshare.com/i/kEs.svg',
          backgroundColor: 'white',
          primaryColor: '#E17D00',
          secondaryColor: 'rgba(225, 125, 0, 0.5)',
          fontFamily: 'Poppins',
          poweredByLogo: 'https://svgshare.com/i/nf0.svg',
        },
      },
    },
    {
      id: '2',
      title: 'Welcome to Devoxx Quiz',
      description:
        'This quiz is made for you in order to book your interview slot and possibily join our xFamily.',
      kind: 'FORM',
      initializeButtonText: 'Register now',
      formSetup: {
        formName: 'Registration',
        formTitle: 'registration form',
        submitUrl: '/api/v1/quiz-instance',
        submitMethod: 'POST',
        elements: [
          {
            id: '1',
            type: 'input',
            name: 'firstName',
            label: 'FIRST NAME',
            placeholder: 'First name',
            required: true,
            htmlType: 'text',
          },
          {
            id: '2',
            type: 'input',
            name: 'lastName',
            label: 'LAST NAME',
            placeholder: 'Last name',
            required: true,
            htmlType: 'text',
          },
          {
            id: '3',
            type: 'input',
            name: 'email',
            label: 'EMAIL',
            placeholder: 'xAtendee@x-hub.io',
            required: true,
            htmlType: 'email',
          },
          {
            id: '4',
            type: 'input',
            name: 'phoneNumber',
            label: 'PHONE NUMBER',
            placeholder: '+212 6 xx xx xx xx',
            required: true,
            htmlType: 'phone',
          },
          {
            id: '5',
            type: 'button',
            name: 'Register',
            innerText: 'Register now',
            htmlType: 'submit',
          },
        ],
        theme: {
          id: '4',
          active: true,
          backgroundImage: 'https://svgshare.com/i/kEs.svg',
          backgroundColor:
            'linear-gradient(116.52deg, rgba(225, 125, 0, 0.2) 0%, rgba(59, 58, 59, 0.2) 98.56%)',
          primaryColor: '#E17D00',
          secondaryColor: 'rgba(225, 125, 0, 0.5)',
          fontFamily: 'Poppins',
          poweredByLogo: 'https://svgshare.com/i/nf0.svg',
        },
      },
    },
  ],
  active: true,
  initialized: null,
  themes: [
    {
      id: '1',
      active: true,
      backgroundImage:
        'https://res.cloudinary.com/dizlyig0d/image/upload/v1663631144/image_1_axc4az.png',
      backgroundColor: 'white',
      primaryColor: '#E17D00',
      secondaryColor: 'rgba(225, 125, 0, 0.5)',
      fontFamily: 'Poppins',
      poweredByLogo: 'https://svgshare.com/i/nf0.svg',
    },
    {
      id: '2',
      active: true,
      backgroundImage:
        'https://res.cloudinary.com/dizlyig0d/image/upload/v1663631144/image_1_axc4az.png',
      backgroundColor:
        'linear-gradient(116.52deg, rgba(225, 125, 0, 0.2) 0%, rgba(59, 58, 59, 0.2) 98.56%)',
      primaryColor: '#E17D00',
      secondaryColor: 'rgba(225, 125, 0, 0.5)',
      fontFamily: 'Poppins',
      poweredByLogo: 'https://svgshare.com/i/nf0.svg',
    },
  ],
  culturalQuiz: true,
  quizInstruction: {
    numberOfQuestions: 5,
    passMark: 80,
    totalAttempts: 1,
    timeLimit: 300,
    description: 'Questions must be answered or you can’t go to the next one.',
    restriction: 'You are not allowed to go back and change your answers.',
  },
  seniorityLevels: [
    {
      id: '2',
      label: 'INTERMEDIATE',
    },
    {
      id: '1',
      label: 'JUNIOR',
    },
    {
      id: '3',
      label: 'SENIOR',
    },
  ],
};

export const GET_LOCALSTORAGE_CURRENT_QUESTION = {
  question: {
    id: '13',
    content: 'What does @RequestMapping annotation?',
    isMultipleChoice: true,
    index: 2,
    answers: [
      {
        id: '20',
        content:
          'The @RequestMapping annotation maps the request with the method. It is applied on the method',
      },
      {
        id: '47',
        content:
          'It tells to the Spring that any HTTP request should map to the corresponding method.',
      },
      {
        id: '48',
        content: 'sed to bind a web request parameter to a method parameter',
      },
      {
        id: '74',
        content:
          'informs to the Spring to render the result back to the caller',
      },
    ],
    totalCorrectAnswers: 2,
  },
  timeLimit: 120,
  startDate: '2022-09-22T21:15:50.7529414',
  endDate: '2022-09-22T21:17:50.7529414',
};

export const GET_LOCALSTORAGE_ISABLETORATE = true;

export const GET_LOCALSTORAGE_NUMBEROFQUESTIONS = 20;
export const GET_LOCALSTORAGE_SESSION_ID = { sessionId: '1' };
