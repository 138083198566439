import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomeRouteConfig } from '../pages/home/route';
import { QuizInstructionsRouteConfig } from '../pages/quizInstructions/route';
import { RateRouteConfig } from '../pages/rate/route';
import { ScoreRouteConfig } from '../pages/score/route';
import { LevelRouteConfig } from '../pages/level/route';
import { ThemeRouteConfig } from '../pages/theme/route';
import { QuestionRouteConfig } from '../pages/questions/route';
import { RegisterRouteConfig } from '../pages/registration/route';
import { CultureQuizRouteConfig } from '../pages/CultureQuizPage/route';
import { NotFoundRouteConfig } from '../pages/NotFound/route';
import { XQuizRoute } from '../types';
import { TimeOutConfig } from '../pages/timeOutPage/route';

type AppRoutingProps = {
  routes: string;
};
function renderRouteElement(config: XQuizRoute<any>) {
  return React.createElement(config.Component, config.ComponentProps);
}

function makeRoutePropsFromConfig(config: XQuizRoute<any>) {
  return {
    path: config.path,
    element: renderRouteElement(config),
  };
}
const GLOBAL_ROUTE_CONFIG = Object.freeze({
  APP_ROUTES_CONFIG: [
    makeRoutePropsFromConfig(HomeRouteConfig),
    makeRoutePropsFromConfig(QuizInstructionsRouteConfig),
    makeRoutePropsFromConfig(ThemeRouteConfig),
    makeRoutePropsFromConfig(LevelRouteConfig),
    makeRoutePropsFromConfig(RegisterRouteConfig),
    makeRoutePropsFromConfig(RateRouteConfig),
    makeRoutePropsFromConfig(QuestionRouteConfig),
    makeRoutePropsFromConfig(ScoreRouteConfig),
    makeRoutePropsFromConfig(CultureQuizRouteConfig),
    makeRoutePropsFromConfig(NotFoundRouteConfig),
    makeRoutePropsFromConfig(TimeOutConfig),
  ],
  APP_ROUTES_FOR_QUIZES_CREATED_BY_XPORTFOLIO: [
    makeRoutePropsFromConfig(HomeRouteConfig),
    makeRoutePropsFromConfig(QuizInstructionsRouteConfig),
    makeRoutePropsFromConfig(RegisterRouteConfig),
    makeRoutePropsFromConfig(RateRouteConfig),
    makeRoutePropsFromConfig(QuestionRouteConfig),
    makeRoutePropsFromConfig(ScoreRouteConfig),
    makeRoutePropsFromConfig(CultureQuizRouteConfig),
    makeRoutePropsFromConfig(NotFoundRouteConfig),
    makeRoutePropsFromConfig(TimeOutConfig),
  ],
});

export default function AppRouting({ routes }: AppRoutingProps) {
  return (
    <Routes>
      {GLOBAL_ROUTE_CONFIG[routes]?.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
}
